<template>
<div v-if="isApplicable">
    <v-text-field solo :label="label" v-model="input" autocomplete
          :rules="rule" validate-on-blur :hint="hint"></v-text-field>
</div>
</template>

<script>
export default {
  name: 'NameField',
  props: {
    label: String,
    value: String,
    isApplicable: Boolean,
    hint: String,
    rule: {
      default: function () {
        return [(input) => {
        // depends on the parent component to determine if this input is a applicable field for the user.
        // e.g. if the user is a giver, then charity input field will be hidden and no validation is needed.
          if (this.isApplicable) {
            return (input && /^[a-zA-Z0-9\s]+$/.test(input) && input.length <= 30) ||
              'Must be English letters a-z, A-Z, or numbers 0-9 with length between 1-30'
          } else {
            return true
          }
        }]
      }
    }
  },
  data () {
    return {
      input: this.value
    }
  },
  watch: {
    input (newValue) {
      this.$emit('update:value', newValue)
    }
  }
}
</script>
